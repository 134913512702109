import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "热搜词"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请填写名称"
        }]
      }],
      expression: "[\n                        'name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写名称',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      placeholder: "请输入英文"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "时间"
    }
  }, [_c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["time", {
        rules: [{
          required: true,
          message: "请填写时间"
        }]
      }],
      expression: "[\n                        'time',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写时间',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      "disabled-date": _vm.disabledDate,
      "show-time": {
        hideDisabledOptions: true,
        defaultValue: [_vm.$moment("00:00:00", "HH:mm:ss"), _vm.$moment("23:59:59", "HH:mm:ss")]
      },
      format: "YYYY-MM-DD HH:mm:ss"
    },
    on: {
      change: _vm.onDateChangeTime
    }
  }), _c("span", [_vm._v("（美国时间）")])], 1), _c("a-form-item", {
    attrs: {
      label: "搜索结果"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "[\n                        'type',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large"
    },
    on: {
      change: function change(value) {
        return _vm.handleBrandChange(value);
      }
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 搜索词 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 搜索商品 ")])], 1)], 1)], 1), _vm.createForm.getFieldValue("type") == 2 ? _c("a-form", {
    attrs: {
      form: _vm.searchForm
    }
  }, [_c("a-form-item", {
    staticClass: "star",
    attrs: {
      label: "添加商品"
    }
  }), _c("div", {
    staticClass: "brand_flex"
  }, [_c("a-form-item", {
    attrs: {
      label: "类目"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["parentcategoryid"],
      expression: "['parentcategoryid']"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "一级类目",
      size: "large"
    },
    on: {
      change: function change(value) {
        return _vm.handleProvinceChange(value);
      }
    }
  }, _vm._l(_vm.seriesCateList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["categoryid"],
      expression: "['categoryid']"
    }],
    staticStyle: {
      "margin-left": "12px",
      width: "262px"
    },
    attrs: {
      placeholder: "二级类目",
      size: "large"
    }
  }, _vm._l(_vm.secategoryList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    staticStyle: {
      "margin-left": "40px"
    },
    attrs: {
      label: "品牌"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["brandid"],
      expression: "['brandid']"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large"
    }
  }, _vm._l(_vm.brandList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "货号"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sku"],
      expression: "['sku']"
    }]
  }), _c("span", [_c("a-button", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v("查询")])], 1)], 1)], 1) : _vm._e()], 1), _vm.createForm.getFieldValue("type") == 2 ? _c("a-card", {
    staticStyle: {
      "margin-top": "24px",
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      "row-selection": {
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
        fixed: true
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "imgurls" ? _c("span", [record.imgurls ? _c("img", {
            staticClass: "product_img",
            attrs: {
              src: _vm.splitImg(record)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record));
              }
            }
          }) : _vm._e()]) : key == "parentcategoryname" ? _c("span", [_c("span", [_vm._v(_vm._s("".concat(record.parentcategoryname, "\u2014").concat(record.categoryname)))])]) : key == "saleprice" ? _c("span", [record.saleprice ? _c("a", [_vm._v(_vm._s(record.saleprice))]) : _vm._e()]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1) : _vm._e(), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    staticClass: "btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("确定")])], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };